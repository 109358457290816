import React from 'react';

import Layout from '../components/Layout';
import HomepagePosts from '../components/HomepagePosts';

import { Link, StaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Img from 'gatsby-image';
import Icon from "@bahaide/shared/components/Icon";

function IndexPage() {
    return (
        <StaticQuery
            query={graphql`
                query {
                    podcastAbdulbahaImage: file(relativePath: { eq: "2556469-master.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 300) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                    podcastImage: file(relativePath: { eq: "podcast.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 300) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                    video1Image: file(
                        relativePath: { eq: "jugend-gestaltet-video.jpg" }
                    ) {
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 300) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                    video2Image: file(
                        relativePath: { eq: "nachbarschaft-hda-video.jpg" }
                    ) {
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 300) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                    video3Image: file(
                        relativePath: { eq: "vorderste-front-video.jpg" }
                    ) {
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 300) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            `}
            render={data => (
                <Layout
                    site="aktuelles"
                    title="Aktuelles aus der deutschen und weltweiten Bahá'í-Gemeinde"
                    description="Aktuelles aus der deutschen und weltweiten Bahá'í-Gemeinde"
                >
                    <main id="main" role="main">
                        <div className="intro-block">
                            <div className="container">
                                <h1 className="text-3xl italic pt-12 pb-6">
                                    Aktuelles aus der deutschen und weltweiten
                                    Bahá'í-Gemeinde
                                </h1>
                            </div>
                        </div>
                        <div className="container mb-6">
                            <HomepagePosts />
                            <Link
                                className="flex justify-end text-base"
                                to="/archiv/"
                            >
                                Weitere Artikel &raquo;
                            </Link>
                        </div>
                        <div
                            className="text-center"
                            style={{
                                backgroundColor: '#f8f6f4',
                            }}
                        >
                            <div className="container p-12">
                                <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl h1 text-blue-900 serif italic">
                                    „Die Erde ist nur ein Land, und alle
                                    <br />
                                    Menschen sind seine Bürger.“
                                </span>
                            </div>
                        </div>
                        <div className="container my-6">
                            <div className="flex flex-col md:flex-row my-6 justify-between">
                                <OutboundLink
                                    style={{
                                        color: '#5c5956'
                                    }}
                                    className="group flex flex-col pr-15 hover:bg-gray-200 overflow-hidden hover:shadow-lg mr-6 mb-6 md:mb-0 md:w-1/3"
                                    target="_blank"
                                    href="https://soundcloud.com/bahai_de/einblicke-in-das-leben-und-wirken-des-bab"
                                >
                                    <div className="md:flex-shrink-0">
                                        <Img
                                            fluid={
                                                data.podcastImage
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                    <div className="flex-1 text-left py-4 px-3 group">
                                        <h2 className="text-xl font-bold text-blue-900 serif">
                                            Podcast{' '}
                                            <Icon
                                                icon="external-link"
                                                className="text-gray-600 text-sm" />
                                        </h2>
                                        <div className="text-base">
                                        Untrennbar verbunden mit der Geschichte Bahá’u’lláhs und der Entstehung der Bahá’í-Religion ist die Geschichte des Báb und der von ihm offenbarten Bábí-Religion. Er wird auch als der Herold Bahá’u’lláhs, dem Stifter der Bahá’í-Religion, bezeichnet. Dennoch stellt die Bábí-Religion eine eigenständige Offenbarungsreligion dar.
                                        </div>
                                    </div>
                                </OutboundLink>
                                <OutboundLink
                                    style={{
                                        color: '#5c5956'
                                    }}
                                    className="group flex flex-col pr-15 hover:bg-gray-200 overflow-hidden hover:shadow-lg mr-6 mb-6 md:mb-0 md:w-1/3"
                                    target="_blank"
                                    href="https://soundcloud.com/bahai_de/einblicke-in-das-leben-abdul-bahas"
                                >
                                    <div className="md:flex-shrink-0">
                                        <Img
                                            fluid={
                                                data.podcastAbdulbahaImage
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                    <div className="flex-1 text-left py-4 px-3 group">
                                        <h2 className="text-xl font-bold text-blue-900 serif">
                                            Podcast{' '}
                                            <Icon
                                                icon="external-link"
                                                className="text-gray-600 text-sm" />
                                        </h2>
                                        <div className="text-base">
                                            ʻAbdu'l-Bahá, der älteste Sohn Bahá’u’lláhs und dessen von Ihm selbst autorisierter Ausleger Seines Wortes, hat sein gesamtes Leben dem Dienst an der Menschheit gewidmet. Als eine der Zentralgestalten der Bahá’í-Religion sind seine Taten und Worte eine Widerspiegelung der Offenbarung Bahá’u’lláhs, seine außergewöhnliche Persönlichkeit dient als Vorbild für die Menschen. Als sogenannter Mittelpunkt des Bundes kommt ihm eine in der Religionsgeschichte einmalige Aufgabe zu.

                                        </div>
                                    </div>
                                </OutboundLink>
                                <OutboundLink
                                    style={{
                                        color: '#5c5956'
                                    }}
                                    className="group flex flex-col pr-15 hover:bg-gray-200 overflow-hidden hover:shadow-lg mr-6 mb-6 md:mb-0 md:w-1/3"
                                    target="_blank"
                                    href="https://soundcloud.com/bahai_de/die-rolle-der-jugend-1"
                                >
                                    <div className="md:flex-shrink-0">
                                        <Img
                                            fluid={
                                                data.podcastImage
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                    <div className="flex-1 text-left py-4 px-3 group">
                                        <h2 className="text-xl font-bold text-blue-900 serif">
                                            Podcast{' '}
                                            <Icon
                                                icon="external-link"
                                                className="text-gray-600 text-sm" />
                                        </h2>
                                        <div className="text-base">
                                            Junge Menschen tauschen sich über
                                            ihre Rolle, ihre derzeitigen
                                            Möglichkeiten sich einzubringen, und
                                            Wege, wie Jugendbeteiligung sich
                                            weiter entwickeln kann.
                                        </div>
                                    </div>
                                </OutboundLink>
                            </div>
                            <div className="flex flex-col md:flex-row my-6 justify-between">
                                <OutboundLink
                                    style={{
                                        color: '#5c5956'
                                    }}
                                    className="group flex flex-col pr-15 hover:bg-gray-200 overflow-hidden hover:shadow-lg mr-6 mb-6 md:mb-0 md:w-1/3"
                                    target="_blank"
                                    href="https://www.youtube.com/watch?v=bYc_lHjGEzk"
                                >
                                    <div className="md:flex-shrink-0">
                                        <Img
                                            fluid={
                                                data.video1Image.childImageSharp
                                                    .fluid
                                            }
                                        />
                                    </div>
                                    <div className="flex-1 text-left py-4 px-3 group">
                                        <h2 className="text-xl font-bold text-blue-900 serif">
                                            Video{' '}
                                            <Icon
                                                icon="external-link"
                                                className="text-gray-600 text-sm" />
                                        </h2>
                                        <div className="text-base">
                                            „Jugend gestaltet Zukunft mit
                                            allen!“ findet in Teterow,
                                            Mecklenburg-Vorpommern statt – wie
                                            gestaltet man Jugendbeteiligung mit
                                            allen?
                                        </div>
                                    </div>
                                </OutboundLink>
                                <OutboundLink
                                    style={{
                                        color: '#5c5956'
                                    }}
                                    className="group flex flex-col pr-15 hover:bg-gray-200 overflow-hidden hover:shadow-lg mr-6 mb-6 md:mb-0 md:w-1/3"
                                    target="_blank"
                                    href="https://soundcloud.com/bahai_de/einblicke-in-das-leben-bahaullahs"
                                >
                                    <div className="md:flex-shrink-0">
                                        <Img
                                            fluid={
                                                data.podcastImage
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                    <div className="flex-1 text-left py-4 px-3 group">
                                        <h2 className="text-xl font-bold text-blue-900 serif">
                                            Podcast{' '}
                                            <Icon
                                                icon="external-link"
                                                className="text-gray-600 text-sm" />
                                        </h2>
                                        <div className="text-base">
                                            Das Leben Bahá’u’lláhs, Stifter der
                                            Bahá’i-Religion, erzählt in einigen
                                            kurzen Episoden.
                                        </div>
                                    </div>
                                </OutboundLink>
                                <OutboundLink
                                    style={{
                                        color: '#5c5956'
                                    }}
                                    className="group flex flex-col pr-15 hover:bg-gray-200 overflow-hidden hover:shadow-lg mr-6 mb-6 md:mb-0 md:w-1/3"
                                    target="_blank"
                                    href="https://www.youtube.com/watch?v=iS-PQk5iYPU"
                                >
                                    <div className="md:flex-shrink-0">
                                        <Img
                                            fluid={
                                                data.video2Image.childImageSharp
                                                    .fluid
                                            }
                                        />
                                    </div>
                                    <div className="flex-1 text-left py-4 px-3 group">
                                        <h2 className="text-xl font-bold text-blue-900 serif">
                                            Video{' '}
                                            <Icon
                                                icon="external-link"
                                                className="text-gray-600 text-sm" />
                                        </h2>
                                        <div className="text-base">
                                            Einen Einblick in die Bedeutung des
                                            europäischen Hauses der Andacht für
                                            die Nachbarschaft
                                        </div>
                                    </div>
                                </OutboundLink>
                            </div>
                            {/* <div className="flex flex-col md:flex-row my-6 justify-between">
                                <OutboundLink
                                    style={{
                                        color: '#5c5956'
                                    }}
                                    className="group flex flex-col pr-15 hover:bg-gray-200 overflow-hidden hover:shadow-lg mr-6 mb-6 md:mb-0 md:w-1/3"
                                    target="_blank"
                                    href="https://soundcloud.com/bahai_de/eine-gemeinsame-vorstellung-von-gesellschaftlichem-zusammenhalt"
                                >
                                    <div className="md:flex-shrink-0">
                                        <Img
                                            fluid={
                                                data.podcastImage
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    </div>
                                    <div className="flex-1 text-left py-4 px-3 group">
                                        <h2 className="text-xl font-bold text-blue-900 serif">
                                            Podcast{' '}
                                            <Icon
                                                icon="external-link"
                                                className="text-gray-600 text-sm" />
                                        </h2>
                                        <div className="text-base">
                                            Erste Reflexion einiger Teilnehmer
                                            der Gesprächsreihe „Die Fähigkeit
                                            von Religion und Kultur gemeinsam
                                            gesellschaftlichen Zusammenhalt zu
                                            gestalten“ zu Bedingungen um
                                            gesellschaftlichen Zusammenhalt
                                            aufrechtzuerhalten.
                                        </div>
                                    </div>
                                </OutboundLink>
                                <OutboundLink
                                    style={{
                                        color: '#5c5956'
                                    }}
                                    className="group flex flex-col pr-15 hover:bg-gray-200 overflow-hidden hover:shadow-lg mb-6 md:mb-0 md:w-1/3"
                                    target="_blank"
                                    href="https://www.youtube.com/watch?v=w-tuoO-uoAk"
                                >
                                    <div className="md:flex-shrink-0">
                                        <Img
                                            fluid={
                                                data.video3Image.childImageSharp
                                                    .fluid
                                            }
                                        />
                                    </div>
                                    <div className="flex-1 text-left py-4 px-3 group">
                                        <h2 className="text-xl font-bold text-blue-900 serif">
                                            Video{' '}
                                            <Icon
                                                icon="external-link"
                                                className="text-gray-600 text-sm" />
                                        </h2>
                                        <div className="text-base">
                                            Erfahrungen aus der weltweiten
                                            Bahá'í-Gemeinde in ihrer Bestrebung
                                            lebenssprühende Umfelder mit anderen
                                            zu gestalten
                                        </div>
                                    </div>
                                </OutboundLink>
                            </div> */}
                            <Link
                                className="flex justify-end text-base"
                                to="/podcasts-videos/"
                            >
                                Weitere Podcasts und Videos &raquo;
                            </Link>
                        </div>
                    </main>
                </Layout>
            )}
        />
    );
}

export default IndexPage;
