import React, { Fragment } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

function HomepagePosts() {
    return (
        <StaticQuery
            query={graphql`
                query {
                    latestPost: allMarkdownRemark(
                        sort: { fields: [frontmatter___date], order: DESC }
                        limit: 1
                    ) {
                        edges {
                            node {
                                frontmatter {
                                    title
                                    date(
                                        formatString: "DD. MMMM YYYY"
                                        locale: "de"
                                    )
                                    feature_image {
                                        childImageSharp {
                                            fluid(maxWidth: 1024, quality: 80) {
                                                ...GatsbyImageSharpFluid_noBase64
                                            }
                                        }
                                    }
                                    thumbnail_image {
                                        childImageSharp {
                                            fluid(maxWidth: 1024, quality: 80) {
                                                ...GatsbyImageSharpFluid_noBase64
                                            }
                                        }
                                    }
                                }
                                excerpt(pruneLength: 100, format: PLAIN)
                                fields {
                                    slug
                                }
                            }
                        }
                    }
                    secondRowPosts: allMarkdownRemark(
                        sort: { fields: [frontmatter___date], order: DESC }
                        limit: 2
                        skip: 1
                    ) {
                        edges {
                            node {
                                frontmatter {
                                    title
                                    date(
                                        formatString: "DD. MMMM YYYY"
                                        locale: "de"
                                    )
                                    feature_image {
                                        childImageSharp {
                                            fluid(
                                                maxWidth: 600
                                                maxHeight: 400
                                                quality: 80
                                            ) {
                                                ...GatsbyImageSharpFluid_noBase64
                                            }
                                        }
                                    }
                                    thumbnail_image {
                                        childImageSharp {
                                            fluid(
                                                maxWidth: 600
                                                maxHeight: 400
                                                quality: 80
                                            ) {
                                                ...GatsbyImageSharpFluid_noBase64
                                            }
                                        }
                                    }
                                }
                                excerpt(pruneLength: 100, format: PLAIN)
                                fields {
                                    slug
                                }
                            }
                        }
                    }
                    thirdRowPosts: allMarkdownRemark(
                        sort: { fields: [frontmatter___date], order: DESC }
                        limit: 2
                        skip: 3
                    ) {
                        edges {
                            node {
                                frontmatter {
                                    title
                                    date(
                                        formatString: "DD. MMMM YYYY"
                                        locale: "de"
                                    )
                                    feature_image {
                                        childImageSharp {
                                            fluid(
                                                maxWidth: 600
                                                maxHeight: 400
                                                quality: 80
                                            ) {
                                                ...GatsbyImageSharpFluid_noBase64
                                            }
                                        }
                                    }
                                    thumbnail_image {
                                        childImageSharp {
                                            fluid(
                                                maxWidth: 600
                                                maxHeight: 400
                                                quality: 80
                                            ) {
                                                ...GatsbyImageSharpFluid_noBase64
                                            }
                                        }
                                    }
                                }
                                excerpt(pruneLength: 100, format: PLAIN)
                                fields {
                                    slug
                                }
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                    {data.latestPost.edges.length ? (
                        <Link
                            className="flex flex-col my-6 bg-blue-800 md:flex-row hover:bg-blue-900 hover:shadow-lg"
                            to={`/artikel${data.latestPost.edges[0].node.fields.slug}`}
                        >
                            <div className="md:flex-shrink-0 w-full md:w-1/2">
                                <Img
                                    className="mx-auto"
                                    fluid={
                                        data.latestPost.edges[0].node
                                            .frontmatter.feature_image
                                            .childImageSharp.fluid ||
                                        data.latestPost.edges[0].node
                                            .frontmatter.thumbnail_image
                                            .childImageSharp.fluid
                                    }
                                />
                            </div>
                            <div className="flex-1 self-center text-left p-6">
                                <h2 className="text-3xl font-bold text-white serif">
                                    {
                                        data.latestPost.edges[0].node
                                            .frontmatter.title
                                    }
                                </h2>
                                <div className="text-base text-gray-500">
                                    {
                                        data.latestPost.edges[0].node
                                            .frontmatter.date
                                    }
                                </div>
                                <div className="text-base text-white">
                                    {data.latestPost.edges[0].node.excerpt}
                                </div>
                            </div>
                        </Link>
                    ) : null}
                    {data.secondRowPosts.edges.length ? (
                        <div className="flex flex-col md:flex-row my-6 justify-between">
                            {data.secondRowPosts.edges.map((post, i) => {
                                // Null value possible
                                const article_image =
                                    post.node.frontmatter.hasOwnProperty(
                                        'thumbnail_image'
                                    ) &&
                                    post.node.frontmatter.thumbnail_image !==
                                        null
                                        ? post.node.frontmatter.thumbnail_image
                                        : post.node.frontmatter.feature_image;
                                return (
                                    <Link
                                        className="flex flex-col pr-15 hover:bg-gray-200 hover:shadow-lg overflow-hidden md:w-1/2 md:first:mr-6 mb-6 md:mb-0"
                                        key={i}
                                        to={`/artikel${post.node.fields.slug}`}
                                    >
                                        {article_image && (
                                            <div className="md:flex-shrink-0">
                                                <Img
                                                    fluid={
                                                        article_image
                                                            .childImageSharp
                                                            .fluid
                                                    }
                                                />
                                            </div>
                                        )}
                                        <div className="flex-1 text-left py-4 px-3">
                                            <h2 className="text-xl font-bold text-blue-900 serif">
                                                {post.node.frontmatter.title}
                                            </h2>
                                            <div className="text-base text-gray-500">
                                                {post.node.frontmatter.date}
                                            </div>
                                            <div className="text-base" 
                                                style={{
                                                    color: '#5c5956'
                                                }}>
                                                {post.node.excerpt}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    ) : null}
                    {data.thirdRowPosts.edges.length ? (
                        <div className="flex flex-col md:flex-row my-6 justify-between">
                            {data.thirdRowPosts.edges.map((post, i) => {
                                // Null value possible
                                const article_image =
                                    post.node.frontmatter.hasOwnProperty(
                                        'thumbnail_image'
                                    ) &&
                                    post.node.frontmatter.thumbnail_image !==
                                        null
                                        ? post.node.frontmatter.thumbnail_image
                                        : post.node.frontmatter.feature_image;
                                return (
                                    <Link
                                        className="flex flex-col pr-15 hover:bg-gray-200 hover:shadow-lg overflow-hidden md:w-1/2 md:first:mr-6 mb-6 md:mb-0"
                                        key={i}
                                        to={`/artikel${post.node.fields.slug}`}
                                    >
                                        {article_image && (
                                            <div className="md:flex-shrink-0">
                                                <Img
                                                    fluid={
                                                        article_image
                                                            .childImageSharp
                                                            .fluid
                                                    }
                                                />
                                            </div>
                                        )}
                                        <div className="flex-1 text-left py-4 px-3">
                                            <h2 className="text-xl font-bold text-blue-900 serif">
                                                {post.node.frontmatter.title}
                                            </h2>
                                            <div className="text-base text-gray-500">
                                                {post.node.frontmatter.date}
                                            </div>
                                            <div className="text-base"
                                                style={{
                                                    color: '#5c5956'
                                                }}>
                                                {post.node.excerpt}
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                    ) : null}
                </>
            )}
        />
    );
}

export default HomepagePosts;
