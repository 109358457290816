import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PrimaryLayout from '@bahaide/shared/components/PrimaryLayout';

import '../styles/site.css';

function Layout({ children, title, image, description, site }) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    site {
                        siteMetadata {
                            defaultTitle: title
                            titleTemplate
                            defaultDescription: description
                            siteUrl
                            defaultImage: image
                            twitterUsername
                        }
                    }
                }
            `}
            render={data => (
                <PrimaryLayout
                    site={site}
                    children={children}
                    metadata={data.site.siteMetadata}
                    environment={process.env.GATSBY_ACTIVE_ENV}
                    title={title}
                    image={image}
                    description={description}
                />
            )}
        />
    );
}

export default Layout;
